<template>
    <div class="pb-10 h-100 ml-2">
        <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
            <div class="breadcrumbactive">
                <div >
                    <span class="pointer" @click="goToCompanyList">Company</span>
                    <span>Board Member</span>
                </div>
            </div>
            <div class="d-flex mx-4" style="flex-grow: 1;position: relative;">
                <i class="icon icon-magnify fs-15" style="position: absolute; left: 10px;top: 6px;"></i>
                <i v-if="search_key !=''" class="icon icon-close fs-20" style="position: absolute; right: 10px;color: #707070 !important;cursor: pointer;top:6px" @click="clearSearch"></i>
                <input type="text" class="search-input-field" v-model="search_key" @input="loadBoardMeeting" placeholder="Search by Board Member Name" />
            </div>
            <div>
                <div class="d-flex flex-wrap align-items-center mr-4">
                    <!-- <button class="btn btn-smm btn-new-secondary" style="border: 0 !important;border-radius: 6px !important;" @click="showFilters()">
                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/filter_icon.svg" style="margin-right: 8px;">Filter
                    </button> -->
                    <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" class="btn btn-smm btn-danger ml-3" style="border-radius: 6px !important;"  :disabled="consultant_selected.length < 1" @click="showDeleteComp">- Delete</button>
                    <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" class="btn btn-smm text-white btn-success ml-3" style="border-radius: 6px !important;" @click="createNew">+
                        Add New Board Member
                    </button>
                </div>
            </div>
        </div>
        <div class="pr-2 pb-20">
            <div class="w-100">
                <div class="table-start pt-8 pb-10" style="min-height: 30em;">
                    <div v-if="!ajaxCallInProgress && board_meeting_list.length == 0" class="text-center font-weight-bold">
                        <img src="/static/images/boardmember-illust.svg" alt="" width="400px">
                        <div class="mt-12 text-secondary fw-500 fs-14">No Board Members added yet!</div>
                    </div>
                    <div v-if="ajaxCallInProgress" class="text-center">
                        <div class="d-flex align-items-center justify-content-center">
                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                        </div>
                    </div>
                    <table v-else-if="!ajaxCallInProgress && board_meeting_list.length" class="admin-new-table w-100" style="border-collapse: collapse; position: relative;padding-left:10px" width="100%">
                        <thead>
                            <tr>
                                <th class="text-center">
                                    <label v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" :class="{'v-hidden':board_meeting_list.length == 0}" class="custom-control custom-checkbox digi-checkbox" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                        <input type="checkbox" v-model="check_all" class="custom-control-input" @change="checkAll" name="select_all" />
                                        <span class="custom-control-indicator" style="color: #fff !important; font-size: 20px; top: -10px !important;"></span>
                                        <span class="form-check-description fw-600"></span>
                                    </label>
                                </th>
                                <th class="text-center"></th>
                                <th>Board Member Name</th>
                                <th></th>
                                <th>Personal Email ID</th>
                                <th></th>
                                <th>Access Entry <br>Date and Time</th>
                                <!-- <th>Access End <br>Date and Time</th> -->
                                <th>Password</th>
                                <th>Enable Access</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <tr v-if="!ajaxCallInProgress && board_meeting_list.length == 0" class="text-center font-weight-bold">
                                <td colspan="11">
                                    <img src="/static/images/boardmember-illust.svg" alt="" width="400px">
                                    <div class="mt-2">No Board Members added yet!</div>
                                </td>
                            </tr> -->
                            <tr v-for="(consultant,index) in board_meeting_list" :key="index">
                                <td class="text-center">
                                    <label v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" class="custom-control custom-checkbox digi-checkbox text-center" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                        <input class="custom-control-input" type="checkbox" v-model="consultant.checked" @change="selectConsultant($event, consultant.id, index)"
                                        name="select_one" />
                                        <span class="custom-control-indicator" style="font-size: 20px; top: -10px !important;"></span>
                                        <span class="form-check-description fw-600"></span>
                                    </label>
                                </td>
                                <td class="text-center">
                                    <el-tooltip v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" class="item" effect="dark" content="Edit" placement="top">
                                        <button @click="editConsultant(consultant.id)" class="pointer btn-icon pt-3" style="padding:0px!important;">
                                            <img src="/static/images/edit-new.svg" height="15px;">
                                        </button>
                                    </el-tooltip>
                                </td>
                                <td>{{consultant.full_name}}</td>
                                <td></td> 
                                <td style="text-transform: lowercase !important;">{{consultant.personal_email}}</td>
                                <td></td> 
                                <td>{{consultant.start_date_time || '-'}}</td>
                                <!-- <td>{{consultant.end_date_time  || '-'}}</td> -->
                                <td>
                                    <button :disabled="loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'consultant'" @click="regeneratePassword(consultant.id)" style="border-radius: 6px !important;" class="btn btn-smm btn-new-secondary text-white">Resend</button>
                                </td>
                                <td class="text-center">
                                    <span :class="{'pointer-events-none':loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'consultant'}" @click="enableAccess(consultant)">
                                        <el-switch :disabled="loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'consultant'" v-model="consultant.access"></el-switch>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="!ajaxCallInProgress && board_meeting_list.length" class="d-flex justify-content-end align-items-center bg-white py-2 pb-10 px-4" style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
                    <multiselect class="diginew-multiselect" :options="pageLimit" :close-on-select="true" v-model.number="limit" :max-height="210" open-direction="top" :show-labels="false" :searchable="false" :allow-empty="false" :block-keys="['Delete']" placeholder="" style="width: 4.2em !important;padding-right: 10px!important;">
                    </multiselect>
                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/down-arrow-grey.svg" style="margin-left: -20px;">
                    <span class="page-info pr-2 pl-6 fw-500" style="height: 2.4rem;line-height: 2.4rem;color: #303031;font-size: 14px;">Per page</span>
                    <button @click="firstPage()" :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="mt-1 pointer pagination-list">
                        <el-tooltip class="item" effect="dark" content="First Page" placement="top">
                            <i class="icon-chevron-double-left"></i>
                        </el-tooltip>
                    </button>
                    <button @click="previousPage()" :disabled="skip == 0" :class="{'not-allowed' : skip == 0}" class="pagination-list mt-1 ml-2 pointer">
                        <el-tooltip class="item" effect="dark" content="Previous Page" placement="top">
                            <i class="icon icon-chevron-left"></i>
                        </el-tooltip>
                    </button>
                    <span class="px-2 fw-500 fs-14 mt-1">Showing {{getSkipCount}} - {{total>skip+limit?skip+limit:total}} of {{total}}</span>
                    <button @click="nextPage()" :disabled="skip_temp >= total || total <= 10" :class="{'not-allowed' : skip_temp >= total || total <= 10}" class="pagination-list mt-1 mr-2 pointer">
                        <el-tooltip class="item" effect="dark" content="Next Page" placement="top">
                            <i class="icon icon-chevron-right"></i>
                        </el-tooltip>
                    </button>
                    <button @click="lastPage()" :disabled="temp_total == total || total <= 10" :class="{'not-allowed' : temp_total == total || total <= 10}" class="mt-1 pointer pagination-list">
                        <el-tooltip class="item" effect="dark" content="Last Page" placement="top">
                            <i class="icon-chevron-double-right"></i>
                        </el-tooltip>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="display_filters" class="scm-filters fadeInDown" :class="{'fadeInUp':hide_filters == true}" style="background-color: #e6ecff !important;height: 250px !important;">
            <filter-data :showfilterData="showfilterData" @hideFilters="hideFilters"></filter-data>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
        <delete-board-modal v-if="delete_board" @hideDeleteBoardModal="hideDeleteBoardModal" @deleteBoardSuccess="deleteBoardSuccess" modal_name='delete_company_modal' ></delete-board-modal>
    </div>
</template>
<script>
import { HalfCircleSpinner } from 'epic-spinners';
const FilterData = () => import('./FilterData');
import { SweetModal } from 'sweet-modal-vue';
import consultant from "../mixins/consultant.js"
import DeleteBoardModal from './DeleteBoardModal.vue';
export default({
    data(){
        return{
            warning_msg: '',
            success_msg: '',
            ajaxCallInProgress: false,
            limit:10,
            pageLimit: [],
            showfilterData: {
                started_on:"",
                department_head:[],
                business_unit_id:[],
                department_name:[]
            },
            filterData: {
                started_on:"",
                department_head:[],
                business_unit_id:[],
                department_name:[]
            },
            display_filters: false,
            hide_filters: false,
            skip: 0,
            limit: 10,
            total: 0,
            skip_temp:0,
            temp_total:0,
            board_meeting_list: [],
            search_key: "",
            delete_board: false,
            check_all: false,
            consultant_selected: [],
            group_type: 'investor'
        }
    },
    mixins:[consultant],
    components: {
        FilterData,
        SweetModal,
        HalfCircleSpinner,
        DeleteBoardModal
    },
    methods: {
        clearSearch(){
            this.search_key = ""
            this.loadBoardMeeting();
        },
        goToCompanyList() {
            this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
        },
        hideDeleteBoardModal() {
            this.delete_board = false;
            // this.loadBoardMeeting();
        },
        async deleteBoardSuccess() {
            this.deleteComp();
        },
        showDeleteComp() {
            if(this.consultant_selected.length){
                this.delete_board = true;
                setTimeout(() => {
                    this.$modal.show('delete_company_modal');
                }, 500);
            }
        },
        async deleteComp() {
            try {
                let params = {
                    id: this.consultant_selected
                }
                let response = await this.deleteConsultant(params)
                if(response.status_id == 1) {
                    this.success_msg = response.message
                    this.$refs.success_modal.open();
                    this.consultant_selected = [];
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.loadBoardMeeting();
                    }, 2000);
                }
            }
            catch(err) {
                // this.ajax_call_in_progress = false;
                // console.log(err)
                this.warning_msg = err;
                this.$refs.warning_modal.open();
            }
        },
        selectConsultant(event, id) {
            if (event.target.checked) {
                this.consultant_selected.push(id);
            } else {
                let index = this.consultant_selected.indexOf(id);
                if (index > -1) {
                    this.consultant_selected.splice(index, 1);
                }
            }
            if (this.consultant_selected.length === this.board_meeting_list.length) {
                this.check_all = true;
            } else {
                this.check_all = false;
            }
        },
        checkAll(event) {
            if (event.target.checked === true) {
                this.board_meeting_list.forEach((consultant, index) => {
                    this.board_meeting_list[index].checked = true;
                    this.consultant_selected.push(consultant.id);
                })
            } else {
                this.board_meeting_list.forEach((consultant, index) => {
                    this.board_meeting_list[index].checked = false;
                })
                this.consultant_selected = [];
            }
        },
        showFilters() {
            if (this.display_filters) {
                this.hide_filters = true
                setTimeout(() => {
                    this.display_filters = false
                    this.hide_filters = false
                }, 500);
            } else {
                this.display_filters = true
            }
        },
        hideFilters() {
            this.hide_filters = true
            setTimeout(() => {
                this.display_filters = false
                this.hide_filters = false
            }, 500);
        },
        createNew(){
            this.$router.push('/board/createboard/cmpid/'+this.$route.params.company_id);
        },
        editConsultant(id) {
            this.$router.push(`/board/editboard/cmpid/${this.$route.params.company_id}/${id}`);
        },
        previousPage() {
            this.temp_total = 0
            this.skip_temp = 0
            if (this.skip == 0) {
                return;
            }
            this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
            this.loadBoardMeeting();
        },
        nextPage() {
            this.skip_temp = this.skip + this.limit > this.total ? this.total : this.skip + this.limit;
            if (this.skip_temp >= this.total) {
                return;
            }
            this.skip = this.skip_temp;
            this.loadBoardMeeting();
        },
        firstPage(){
            this.temp_total = 0
            this.skip = 0
            this.limit = this.limit
            this.loadBoardMeeting();
        },
        lastPage() {
            this.skip_temp = 0
            this.skip = 0
            this.temp_total = this.total
            let no_of_pages = Math.ceil(this.total/this.limit)
            this.skip_temp = (no_of_pages * this.limit) - this.limit
            if (this.skip_temp >= this.total) {
                return;
            }
            this.skip = this.skip_temp
            this.loadBoardMeeting();
        },
        async loadBoardMeeting(){
            this.ajaxCallInProgress = true
            let params = {
                skip: this.skip,
                limit: this.limit,
                search_key: this.search_key,
                group_type: this.group_type,
                role:'board_member',
                company_id: this.$route.params.company_id
            }
            let response = await this.getConsultantList(params.skip, params.limit, params.search_key,params.company_id,params.role)
            this.board_meeting_list = response.response
            this.board_meeting_list.forEach(x => {
                x['checked'] = false
                if(x.status == 'active'){
                    x['access'] = true
                }
                else{
                    x['access'] = false
                }
            })
            this.total = response.total_count;
            this.ajaxCallInProgress = false
        },
        async regeneratePassword(id){
            let params = {
                consultant_id: id
            }
            let response = await this.regenerateConsultant(params)
            this.success_msg = response.message
            this.$refs.success_modal.open();
            setTimeout(() => {
                this.$refs.success_modal.close();
                this.loadBoardMeeting();
            }, 2000);
        },
        async enableAccess(consultant){
            let params = {
                status: consultant.status == "active" ? "terminate" : "active"
            }
            let response = await this.editConstultant(params,consultant.id)
            this.success_msg = response.message
            this.$refs.success_modal.open();
            setTimeout(() => {
                this.$refs.success_modal.close();
                this.loadBoardMeeting();
            }, 2000);
        }
    },
    computed: {
        getSkipCount() {
            if(this.total == 0) {
                return 0
            } else {
                let skip_count = this.skip>this.total?this.total:this.skip+1;
                return skip_count;
            }
        },
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
    },
    mounted(){
        // for (let i = 1; i <= 50; i++) {
        //     i += 9
        //     this.pageLimit.push(i);
        // }
        this.loadBoardMeeting()
    },
    created(){
        for (let i = 10; i <= 50; i+=10) {
            this.pageLimit.push(i);
        }
    },
    watch: {
        limit: function(){
            this.loadBoardMeeting()
        },
    }
})
</script>
<style scoped>
.link-name{
    color:#00448b
}
.decoration-none{
    text-decoration: none;
}
.table-view-start {
    background-color: #fff;
}
.search-input-field {
    padding: 8px 9.4px 8px 30px;
    border-radius: 18px !important;
    background-color: #fff;
    width: 100%;
}
.table-start {
    background-color: #fff;
    padding-top: 5px;
    border-radius: 8px;
    overflow-x: auto !important;
}
.scm-filters{
    width: 300px !important;
    height: 300px !important;
    right:100px;
}
.btn-new-green {
    color: #fff;
    background-color: #128807;
    border-radius: 5px;
}
.pointer-events-none {
    pointer-events: none;
}
.not-allowed{
    cursor: no-drop !important;
}
</style>